<template>
  <div class="contact">
    <form action="https://submit-form.com/KC3PGjWa">
      <input
        type="checkbox"
        name="_honeypot"
        style="display: none"
        tabindex="-1"
        autocomplete="off"
      />
      <input type="hidden" name="_redirect" :value="location" />
      <input type="hidden" name="_append" value="false" />
      <input
        type="text"
        id="name"
        name="name"
        placeholder="Name"
        required="true"
      />
      <input
        type="email"
        id="email"
        name="email"
        placeholder="Email"
        required=""
      />
      <textarea
        id="message"
        name="message"
        placeholder="Message"
        required=""
        rows="6"
        cols="50"
      ></textarea>
      <button class="btn outlined" type="submit">Send</button>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.contact form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 1rem;

  & input {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    border: none;
    box-shadow: 2px 2px 5px 2px rgba(59, 78, 89, 1);
  }

  & textarea {
    border-radius: 5px;
    height: 10%;
    border: none;
    box-shadow: 2px 2px 5px 2px $light-gray;
    text-align: center;
  }
}
</style>

<script>
export default {
  title: "Kontakt | David Frenzel",
  name: "Contact",
  computed: {
    location: function () {
      return window.location.href;
    },
  },
};
</script>
