<template :key="componentKey">
  <div>
    <div class="evaluationTool">
      <template v-if="!show && !results">
        <h1>Welcome to the TDD evaluation tool</h1>
        <p>
          Press the start button to start the tool. You will have to answer 17
          Questions in total before you get a proposal if TDD is a beneficial
          practice for your project <br />
          This tool and the logic behind it is part of my bachelor thesis. Check
          it out <a class="link" href="">here</a>
        </p>
        <button @click="start">Start evaluation Tool</button>
      </template>
      <template v-if="show">
        <h2 class="label1">
          Question {{ questionIndex + 1 }}: {{ questions[questionIndex].text }}
        </h2>
        <form>
          <label class="container" v-bind:class="{ checked: isCheckedYes }"
            >Yes
            <input
              type="radio"
              id="yes"
              name="choice"
              value="true"
              @click="changeState('yes')"
            />
          </label>

          <label class="container" v-bind:class="{ checked: isCheckedNo }"
            >No
            <input
              type="radio"
              id="no"
              name="choice"
              value="false"
              @click="changeState('no')"
            />
          </label>
        </form>
        <span>
          <button
            @click="lastQuestion"
            v-if="questionIndex > 0"
            class="btn outlined"
          >
            Back
          </button>
          <button @click="nextQuestion" class="btn contained">
            Next
          </button></span
        >
      </template>
    </div>
    <div class="resultScreen" v-if="results">
      <h1>Your Resulst:</h1>
      <h2>Youre score is {{ score }}</h2>
      <p>{{ getResult() }}</p>
      <br />
      <hr style="width: 90vw" />
      <br />
      <h2 style="font-style: italic">
        These {{ impactQuestions.size }} reasons have impact on the proposed
        decision: (sorted by decreasing impact)
      </h2>
      <fieldset v-for="(item, i) in impactQuestions" :key="item.key">
        <legend>Reason {{ ++i }}</legend>
        <p>{{ getDescription(item) }}</p>
      </fieldset>
    </div>
    <button
      v-if="show || results"
      @click="forceRerender"
      class="btn restart"
      style="background: #a91010"
    >
      Restart
    </button>
  </div>
</template>

<script>
export default {
  name: "evaluationTool",
  data: function () {
    return {
      score: 38.25,
      show: false,
      results: false,
      questionIndex: 0,
      questionOrder: [],
      isCheckedYes: false,
      isCheckedNo: false,
      index: 0,
      impactQuestions: new Map(),
      questions: {
        0: {
          text: "Has the project a dedicated Testing team?",
          points: 1,
          effect: "negative",
          effectOn: "true",
          description:
            "Your project features a dedicated Testing team,the effect is that dedicated acceptance testing teams, like build and release teams, were found to not be able to integrate into the short release cycles that come with TDD. Also, company quality assurance policies did not integrate either.",
        },
        1: {
          text:
            "Is the project still in planning and development has not started yet?",
          points: 5.5,
          effect: "negative",
          effectOn: "false",
          description:
            "While working with either legacy code or code that was not intended to apply TDD in an al-ready existing Project, it was found that refactoring or changing code can easily break functionality without adequate testing possibilities. It is recommended at best to adopt TDD only in new project",
        },
        2: {
          text: "Is the environment stable? (Only when project is not new)",
          points: 1,
          effect: "negative",
          effectOn: "false",
          description:
            "Since your project is also not stable it was found that it will have further negative impact on an adoption of TDD, due to potentially exisitng problems and missing possibilities to detect them",
        },
        3: {
          text:
            "Is the management aware of possible benefits and challenges of TDD like loss in productivity or adoption time needed?",
          points: 3,
          effect: "negative",
          effectOn: "false",
          description:
            "Another problem is that you management is not aware of the possible challenges and benefits that come with TDD. It is found to lead to judgement by management because developers are less productive by writing more tests code and less functionalities, creating more pressure and therefore lowering the developers moral.",
        },
        4: {
          text:
            "Has the project team a high fluctuation rate? (Only think about the project/team members which apply TDD e.g. Developers)",
          points: 3,
          effect: "negative",
          effectOn: "true",
          description:
            "You said thath the project team has a high fluctuation rate which was found to disrupt the team by often introducing new team mebers which then have to learn the exisitng processes and TDD before becoming productive.",
        },
        5: {
          text:
            "In comparison to other projects that the team worked on, is the to be evaluated one bigger or more important?",
          points: 3.5,
          effect: "negative",
          effectOn: "true",
          description:
            "You should also consider that bigger projects where found to need more effort to achieve high test coverages due to the amount of code and components that needs to be tested.",
        },
        6: {
          text: "Are the developers experienced in non-TDD practices?",
          points: 4,
          effect: "negative",
          effectOn: "true",
          description:
            "Because the developers are expereinced with the common approach to testing it will be especially hard for them to adopt such a fundementally different development paradigm.",
        },
        7: {
          text: "Does a concrete software design exist?",
          points: 6.5,
          effect: "negative",
          effectOn: "false",
          description:
            "A lack of design upfront was found to cause insecurity in a lot of developers. Since it is standard with TDD to create the design, to certain extent, while working on the functionalities it will bring a noticeable challenge.",
        },
        8: {
          text:
            "Is there enough buffer to cover a loss in productivity as high as 28%?",
          points: 12.25,
          effect: "negative",
          effectOn: "false",
          description:
            "In terms of productivity the literature varies. It can be said that TDD will have a noticeable loss in productivity that needs to be planned for. Since this buffer is missing it will have strong impact on the outcome of the project.",
        },
        9: {
          text:
            "Do the requirements change after development has already started?",
          points: 3.5,
          effect: "negative",
          effectOn: "true",
          description:
            "With a test last approach a change in requirement can be adressed by simply altering functionality because tests are written afterwards these are usually not affected but with TDD it is likely that the whole software design needs to be revisited because it is not known upfront. Instead it is created while developmen.Additionally tests have to be rewritten because they already exist. This means it often does not fit the new requirement",
        },
        10: {
          text:
            "Is the to be evaluated project set in a controlled environment (laboratory)?",
          points: 2.5,
          effect: "negative",
          effectOn: "false",
          description:
            "A study found that the perfomance TDD scales with how controlled an environment is. In uncontrolled enviroment it was found that the performance of TDD is worse than a test last approach",
        },
        11: {
          text: "Is the planed software a GUI/ Front-End?",
          points: 1,
          effect: "negative",
          effectOn: "true",
          description:
            "Frontends or GUIs are especially hard to test because some key aspects, like relative positioning and size, are hard or even impossible to test. For such cases only a manual test will deliver the expectet results and therefore TDD brings no benefit.",
        },
        12: {
          text:
            "Does the project need to be maintained? (e.g., PoC that will not be developed further)",
          points: 10.5,
          effect: "positive",
          effectOn: "true",
          description:
            "TDD was found to boost the maintainability of the written code. Since your project needs to be maintained this benefit will be seen.",
        },
        13: {
          text:
            "Is there pressure from upper management about bug reduction etc.?",
          points: 3,
          effect: "positive",
          effectOn: "true",
          description:
            "The pressure from upper management will be reduced with the adoption of TDD because of all the expected benefits.",
        },
        14: {
          text:
            "Does the team struggle to create tests in the development process? (Postponing of test creation)",
          points: 3,
          effect: "positive",
          effectOn: "true",
          description:
            "Postponing of test creation is not possible anymore with TDD because tests are needed to develop functionality.",
        },
        15: {
          text:
            "Had the team problems achieving high code coverage with unit tests?",
          points: 16,
          effect: "positive",
          effectOn: "true",
          description:
            "TDD was found to help achieve a higher code coverage because the test is written first and if it fails it needs to be adopted. It is not possible to have a low coverage when doing so.",
        },
        16: {
          text: "Are there problems with understanding the requirements?",
          points: 9,
          effect: "positive",
          effectOn: "true",
          description:
            "Because developers have to think about the requirements first to write a fitting test case misunderstandings would be noticed in the begining of the development and not first in a review session.",
        },
      },
    };
  },
  methods: {
    start() {
      this.show = true;
    },
    nextQuestion() {
      this.saveOrder();
      if (this.questionIndex === 16) {
        this.calculateResult();
        this.show = false;
        this.results = true;
      } else {
        var choice = document.querySelector('input[name="choice"]:checked')
          .value;
        console.log(choice);

        if (this.questions[this.questionIndex].effectOn === choice) {
          this.changeScore();
          this.saveQuestion();
        }
        if (this.questionIndex === 1 && choice === "true") {
          this.questionIndex = 3;
        } else {
          this.questionIndex++;
        }
      }
    },

    saveOrder() {
      this.questionOrder.push(this.questionIndex);
      this.index++;
    },
    saveQuestion() {
      this.impactQuestions.set(
        this.questions[this.questionIndex].points,
        this.questionIndex
      );
    },

    lastQuestion() {
      if (this.index > 0) {
        this.index--;
        this.questionIndex = this.questionOrder[this.index];
        this.questionOrder.pop();
      }
    },

    changeScore() {
      switch (this.questions[this.questionIndex].effect) {
        case "negative":
          this.score = this.score - this.questions[this.questionIndex].points;
          break;
        case "positive":
          this.score = this.score + this.questions[this.questionIndex].points;
          break;
      }
    },

    forceRerender() {
      this.score = 38.25;
      this.show = false;
      this.results = false;
      this.questionIndex = 0;
      this.questionOrder = [];
      this.isCheckedYes = false;
      this.isCheckedNo = false;
      this.index = 0;
      this.impactQuestions = new Map();
    },

    calculateResult() {
      this.impactQuestions = new Map(
        [...this.impactQuestions.entries()].sort(function (a, b) {
          return +/\d+/.exec(a)[0] - +/\d+/.exec(b)[0];
        })
      );
      var arrayTmp = Array.from(this.impactQuestions);
      this.impactQuestions = new Map([...arrayTmp].reverse());
    },

    getDescription(item) {
      return this.questions[item[1]].description;
    },

    changeState(button) {
      switch (button) {
        case "yes":
          this.isCheckedYes = true;
          this.isCheckedNo = false;
          break;
        case "no":
          this.isCheckedNo = true;
          this.isCheckedYes = false;
          break;
      }
    },

    getResult() {
      switch (true) {
        case this.score < 0:
          return "An adoption of TDD can <big>not</big> be recommended. It will do more harm then bring benefits. Please check the below given Reasons for this decision, maybe some can be changed if an adoption of TDD is really wanted.";

        case this.score > 0 && this.score < 38.25:
          return "In the best case you will see slight benefits when applying TDD. Please read the below given Reasons and check again if they apply to your project. Due to the slight benefit TDD can be good for your project but if other projects with a test last approach performed well, it cannot be recommended to apply TDD.";

        case this.score > 38.25:
          return "The evaluated project will benefit from TDD. Please check the reasons below why the tool recommands using TDD.";
      }
    },
  },
};
</script>

<style lang="scss">
.restart {
  position: absolute;
  top: 7%;
  left: 2%;
}

.evaluationTool {
  box-sizing: border-box;
  padding: 4rem 2rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 70%;
  text-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & form {
    padding: 25px 25px;
  }
}

.label1 {
  min-height: 7.125rem;
}

.resultScreen {
  padding: 4rem 0;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 70%;
  text-align: center;
  align-items: center;
}
.container {
  display: inline;
  position: relative;
  padding: 20px 50px;
  border-radius: 5px;
  margin-right: 12px;
  cursor: pointer;
  font-size: 22px;
  border: 2px solid $light-gray;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 300ms ease-in;
}

.checked {
  background-color: $light-gray;
  padding: 20px 60px;
  transition: all 300ms ease-in;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container:hover {
  background: $light-gray;
}
</style>
